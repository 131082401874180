import React, { Component } from 'react';

import { Image } from 'react-bootstrap';
import './accounting.scss';
import Breadbg from '../../assets/images/qsa/breadbg.jpg';

/* eslint-disable-next-line */


class Accounting extends Component {
  render(){
    return (
      <div>
        <div className="inner-banner text-center">
          <div className="bannerImage">
            <Image src={Breadbg}></Image>
          </div>
            <div className="container">
                <ul className="page-breadcrumb">
                  <li>
                    <a href="./index.html">Home</a>
                  </li>
                  <li>QSA</li>
                </ul>		
                <h1> Quick Simple Accounting</h1>
            </div>
        </div>
        <section className="kc-elm kc-css-36515 kc_row" >
          <div className="kc-row-container">
            <div className="kc-wrap-columns">
                <div className="kc-elm kc-css-843552 kc_col-sm-12 kc_column kc_col-sm-12">
                  <div className="kc-col-container">
                      <section className="single-service-proj service-style-one sec-pad solutions-page">
                        <div className="container">
                              <div className="sec-title text-center mb-3">
                                  <h2>Quick Simple Accounting</h2> 
                                  <br/>
                              </div>
                              <p>
                                QSA #1 Cloud base ERP system used by leading Industires in Pakistan. Cloud and on-premise, with open access from anywhere around the globe. Complete Accounting Module with more than 50 report, Inventory Module to get complete track of raw material and finish goods, Sale & Purchase module etc. A complete one solution for Industires and large scale companies. 
                              </p>
                              <p>
                                QSA is cloud-based financial management and accounting software. QSA is suitable for midsize to large organizations and can provide financial reporting and operational insights as well as the ability to automate critical financial processes. QSA manages all core financials and provides complete solution to match all your financial needs. 
                              </p>
                              <p>
                                  QSA delivers an array of core accounting applications such as a general ledger, accounts payable, accounts receivable, cash management, and order management to any accounting department or finance team. It also supports functionality for built-in dashboards, real time reporting, time and expense management, project accounting, revenue management, and global consolidations for multiple entities.
                              </p>
                              <div className="content">
                                    <ul>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Workflow Definitions</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Company Setup</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Bank Setup</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Address Book</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Transaction Profile</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Accounting Calendars for each Module</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Age Parameters for Receivables and Payables</strong></p></li>
                                      <li><i className="fa fa-check-circle"></i><p><strong>•	Analytics</strong></p></li>
                                  </ul>
                              </div>
                          </div>  
                      </section>
                  </div>
                </div>
            </div>
          </div>
      </section>
      </div>      
    );
  }
}

export default Accounting;